import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import AuthorizeRoute from '../../Components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from '../../Components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '../../Components/api-authorization/ApiAuthorizationConstants';

import {
    ToastContainer,
} from 'react-toastify';
import { NOTFOUND } from 'dns';

const PaginaVizualizareStocuri = lazy(() => import('../../Pages/User/Stoc'));

const PaginaLocalAdminCheiAcces = lazy(() => import('../../Pages/LocalAdmin/CheiAcces'));
const PaginaLocalAdminLocatii = lazy(() => import('../../Pages/LocalAdmin/Locatii'));

const PaginaAdminAudit = lazy(() => import('../../Pages/Admin/Audit'));
const PaginaAdminCheiAcces = lazy(() => import('../../Pages/Admin/CheiAcces'));
const PaginaAdminClienti = lazy(() => import('../../Pages/Admin/Clienti'));
const PaginaAdminOrganizatii = lazy(() => import('../../Pages/Admin/Organizatii'));
const PaginaAdminRoluri = lazy(() => import('../../Pages/Admin/Roluri'));
const PaginaAdminUtilizatori = lazy(() => import('../../Pages/Admin/Utilizatori'));

const MainLoadingIndicator = () => {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Vă rugăm să așteptați...</span>
                </Spinner>
            </div>
        </div>
    );
}

const AppMain = () => {

    return (
        <Fragment>
            {/* Stocuri */}

            <Suspense fallback={<MainLoadingIndicator />}>
                <AuthorizeRoute path="/pages/user/stoc" component={PaginaVizualizareStocuri} right="VizStoc" />
            </Suspense>

            {/* Nomenclatoare */}

            <Suspense fallback={<MainLoadingIndicator />}>
                <AuthorizeRoute path="/pages/localAdmin/locatii" component={PaginaLocalAdminLocatii} right="AdmLoc" />
            </Suspense>

            {/* Administrare */}

            <Suspense fallback={<MainLoadingIndicator />}>
                <AuthorizeRoute path="/pages/admin/audit" component={PaginaAdminAudit} right="Audit" />
                <AuthorizeRoute path="/pages/admin/clienti" component={PaginaAdminClienti} right="AdmCli" />
                <AuthorizeRoute path="/pages/admin/organizatii" component={PaginaAdminOrganizatii} right="AdmOrg" />
            </Suspense>

            {/* Autorizare */}

            <Suspense fallback={<MainLoadingIndicator />}>
                <AuthorizeRoute path="/pages/localAdmin/cheiAcces" component={PaginaLocalAdminCheiAcces} right="VizChei" />
                <AuthorizeRoute path="/pages/admin/cheiAcces" component={PaginaAdminCheiAcces} right="AdmChei" />
                <AuthorizeRoute path="/pages/admin/roluri" component={PaginaAdminRoluri} right="AdmRoluri" />
                <AuthorizeRoute path="/pages/admin/utilizatori" component={PaginaAdminUtilizatori} right="AdmUtilizatori" />
            </Suspense>

            {/* API */}

            <Route path="/api" component={NOTFOUND} />
            <Route path="/Identity" component={NOTFOUND} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

            {/* DEFAULT */}

            <Route exact path="/" render={() => (
                <Redirect to="/pages/user/stoc"/>
            )} />

            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;