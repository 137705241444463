import React, { Fragment } from 'react'
import { Component } from 'react'
import { NavLink } from 'reactstrap';
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            authorized: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, authorized } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        if (authorized)
                            return <Component {...props} />
                        else {
                            return <Fragment>
                                    <div>Nu aveți dreptul să accesați această pagină<br /><br />
                                    <NavLink href={"/authentication/logout?returnUrl=" + window.location.href + ApplicationPaths.DefaultLoginRedirectPath}>
                                        Deconectare
                                    </NavLink>
                                </div>
                            </Fragment>
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();

        if (authenticated) {
            if (this.props.right) {
                try {
                    const token = await authService.getAccessToken();
                    const authorizeResponse = await fetch('/api/data/userHasRight?drept=' + this.props.right, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    if ((authorizeResponse.ok) && (authorizeResponse.status != 401)) {
                        const authorizeData = await authorizeResponse.json();
                        this.setState({ ready: true, authenticated: authenticated, authorized: authorizeData });
                    }
                    else {
                        this.setState({ ready: true, authenticated: authenticated, authorized: false });
                    }

                } catch (error) {
                    this.setState({ ready: true, authenticated: authenticated, authorized: false });
                }
            }
            else {
                this.setState({ ready: true, authenticated: authenticated, authorized: true });
            }
        }
        else
            this.setState({ ready: true, authenticated: false, authorized: false }, () => {
        });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
